// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-conference-mdx-js": () => import("./../../../src/templates/ConferenceMDX.js" /* webpackChunkName: "component---src-templates-conference-mdx-js" */),
  "component---src-templates-default-page-mdx-js": () => import("./../../../src/templates/DefaultPageMDX.js" /* webpackChunkName: "component---src-templates-default-page-mdx-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/Index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-platformer-game-mdx-js": () => import("./../../../src/templates/PlatformerGameMDX.js" /* webpackChunkName: "component---src-templates-platformer-game-mdx-js" */),
  "component---src-templates-work-mdx-js": () => import("./../../../src/templates/WorkMDX.js" /* webpackChunkName: "component---src-templates-work-mdx-js" */)
}

